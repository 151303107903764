import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { updateUserValidate } from '../../../helpers/updateUserValidate';
import { updateUser } from '../../../store/actions/user/updateUser';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const accessOptions = ['Yes', 'No'];
const userStatusOptions = ['active', 'revoked'];
const userTypeOption = ['user', 'admin'];

export default function UpdateUserDialog({ selectedUser, dialogOpen, closeDialog }) {
  const [downloadValue, setDownloadValue] = useState('');
  const [uploadValue, setUploadValue] = useState('');
  const [deleteValue, setDeleteValue] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userType, setUserType] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setUserType(selectedUser.type || '');
    setUserStatus(selectedUser.status || '');
    setDownloadValue(selectedUser.download === 1 ? 'Yes' : 'No');
    setUploadValue(selectedUser.upload === 1 ? 'Yes' : 'No');
    setDeleteValue(selectedUser.delete === 1 ? 'Yes' : 'No');
    setFullName(selectedUser.full_name || '');
    setEmail(selectedUser.email || '');
    setUserId(selectedUser.user_id || '');
  }, [selectedUser]);

  const handleUpdate = async () => {
    const err = updateUserValidate(email, fullName, userType, downloadValue, uploadValue, deleteValue, userStatus);
    if (err && err.message && err.message.length >= 1) {
      toast.error(err.message);
    } else {
      const objToSend = {
        email,
        full_name: fullName,
        type: userType,
        download: downloadValue === 'Yes' ? 1 : 0,
        upload: uploadValue === 'Yes' ? 1 : 0,
        delete: deleteValue === 'Yes' ? 1 : 0,
        status: userStatus,
        user_id: userId,
      };
      dispatch(updateUser(objToSend));
      closeDialog();
    }
  };

  return (
    <Box>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Quick Update'}</DialogTitle>
        <DialogContent>
          <Alert severity="info">Kindly note that selecting 'Update' will immediately implement the changes</Alert>

          <Grid container spacing={3} sx={{ mt: 1.5 }}>
            <Grid item md={12} sx={{ width: '100%' }}>
              <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Update User</Typography>
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                defaultValue={fullName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                disabled
                id="outlined-basic"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={email}
                variant="outlined"
                helperText="Email cannot be changed"
              />
            </Grid>
            <Grid item md={4} sx={{ width: '100%' }}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Download Access</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={downloadValue}
                  onChange={(e) => setDownloadValue(e.target.value)}
                  input={<OutlinedInput label="Download Access" />}
                >
                  {accessOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sx={{ width: '100%' }}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Delete Access</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={deleteValue}
                  onChange={(e) => setDeleteValue(e.target.value)}
                  input={<OutlinedInput label="Delete Access" />}
                >
                  {accessOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sx={{ width: '100%' }}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Upload Access</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={uploadValue}
                  onChange={(e) => setUploadValue(e.target.value)}
                  input={<OutlinedInput label="Upload Access" />}
                >
                  {accessOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">User Status</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
                  input={<OutlinedInput label="Upload Access" />}
                >
                  {userStatusOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">User Type</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  input={<OutlinedInput label="Upload Access" />}
                >
                  {userTypeOption.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Switching to the admin role grants the user the capability to oversee and manage other users.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleUpdate} autoFocus variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
