import { Box, Card, CardContent, Typography } from '@mui/material';
import { RiseLoader } from 'react-spinners';

export default function InitialFileUploadLoading() {
  return (
    <Card>
      <CardContent>
        {/* <Grid container spacing={3}>
          <Grid item md={6} sx={{ mt: 2 }}>
            <img
              src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/extracting-text.svg"
              alt="extracting-text"
              loading="lazy"
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Selected Files
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Skeleton variant="rounded" width={'100%'} height={80} />
            </Box>
          </Grid>
        </Grid> */}
        <Box className="flex justify-center align-center column">
          <Typography variant="h6">Extracting Text From your Documents</Typography>
          <Typography sx={{ mb: 3 }}>
            Please allow a few secounds to extract all text from your uploaded documents
          </Typography>
          <RiseLoader color={'#014459'} size={10} />
        </Box>
      </CardContent>
    </Card>
  );
}
