import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import GroupIcon from '@mui/icons-material/Group';
import { useEffect, useState } from 'react';
import Avatar from 'boring-avatars';
import { createUserValidate } from '../../../helpers/createUserValidate';
import { createUser } from '../../../store/actions/user/createUser';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function AlertDialog({ user, companyUsers, company, pageValue, setPageValue }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [password, setPassword] = useState('');
  const [upload, setUpload] = useState(false);
  const [download, setDownload] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userCreationInProgress = useSelector((state) => state.user.userCreationInProgress);
  const statusOptions = ['Active', 'Revoked'];
  const userTypeOptions = ['User', 'Admin'];

  useEffect(() => {
    if (userCreationInProgress === true) {
      setOpen(false);
      setFullName('');
      setEmail('');
      setStatus('');
      setType('');
      setPassword('');
      setUpload('');
      setDownload('');
      setDeleteAccess('');
    }
  }, [userCreationInProgress]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateUser = () => {
    const userObj = {
      fullName,
      email,
      password,
      status,
      type,
      upload,
      download,
      deleteAccess,
    };
    dispatch(createUser(userObj));
  };

  const handleOpenDialog = () => {
    const err = createUserValidate(fullName, email, password, status, type);
    if (err.message) {
      toast.error(err.message);
    } else {
      setOpen(true);
    }
  };

  const createUserAccess = companyUsers.users.length < company.company_access_count;
  // console.log('createUserAccess', companyUsers.users.length, company.company_access_count);
  return (
    <Box>
      <Box sx={{ pt: 4, pb: 3 }} className="flex justify-between align-center">
        <Box>
          <Typography variant="h4">{company.company_name || ''} Create a New User</Typography>
          <Typography sx={{ color: 'gray' }}>
            Take charge of your company's user management and administration.
          </Typography>
        </Box>
        <Button variant="contained" onClick={(e) => setPageValue('company-users')}>
          <GroupIcon style={{ marginRight: '8px' }} />
          View Users
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Card>
          <CardContent>
            <Box>
              <Avatar
                size={40}
                name={company.company_name}
                variant="marble"
                colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
              />
              {/* <AccountBoxIcon style={{ fontSize: '40px' }} /> */}
              <Typography variant="h6">{company.company_name || ''} Users</Typography>
              <Box sx={{ mt: 2 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={(companyUsers && companyUsers.users.length / company.company_access_count) * 100}
                />
              </Box>
              <Box className="flex justify-end" sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: '13px' }}>
                  <span style={{ color: 'black' }}>{companyUsers && companyUsers.users.length} Users </span> /{' '}
                  <span style={{ color: 'gray' }}>{company.company_access_count} Users</span>
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {createUserAccess && user && user.type === 'admin' ? (
        <>
          <Grid sx={{ mt: 4 }} container spacing={1}>
            <Grid item md={4} container>
              <Card>
                <CardContent className="flex justify-center align-center">
                  <img
                    src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/Create+User.svg"
                    alt="user-creation"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={8} container>
              <Box>
                <Card>
                  <CardContent>
                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                      Create a New User
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item md={12} sx={{ width: '100%' }}>
                        <TextField
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          fullWidth
                          id="outlined-basic"
                          label="Full Name"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} sx={{ width: '100%' }}>
                        <TextField
                          type="email"
                          fullWidth
                          value={email}
                          id="outlined-basic"
                          label="Email Address"
                          variant="outlined"
                          onChange={(e) => setEmail(e.target.value)}
                          helperText="Please note that the email cannot be changed once it's created."
                        />
                      </Grid>
                      <Grid item md={6} sx={{ width: '100%' }}>
                        <TextField
                          type="password"
                          fullWidth
                          value={password}
                          id="outlined-basic"
                          label="Temporary Password"
                          variant="outlined"
                          onChange={(e) => setPassword(e.target.value)}
                          helperText="Please make sure to record the temporary password!"
                        />
                      </Grid>

                      <Grid item md={6} sx={{ width: '100%' }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-checkbox-label">User Access</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            input={<OutlinedInput label="User Access" />}
                          >
                            {statusOptions.map((name) => (
                              <MenuItem key={name} value={name}>
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sx={{ width: '100%' }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-checkbox-label">User Type</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            input={<OutlinedInput label="User Type" />}
                          >
                            {userTypeOptions.map((name) => (
                              <MenuItem key={name} value={name}>
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={12} className="flex justify-between">
                        <Box>
                          <Typography variant="h6">Upload Documents</Typography>
                          <Typography>The user has access to upload documents</Typography>
                        </Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>No</Typography>
                          <AntSwitch
                            checked={upload}
                            onChange={(e) => setUpload(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                      </Grid>

                      <Grid item md={12} className="flex justify-between">
                        <Box>
                          <Typography variant="h6">Download Documents</Typography>
                          <Typography>The user has access to upload documents</Typography>
                        </Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>No</Typography>
                          <AntSwitch
                            checked={download}
                            onChange={(e) => setDownload(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                      </Grid>

                      <Grid item md={12} className="flex justify-between">
                        <Box>
                          <Typography variant="h6">Delete Documents</Typography>
                          <Typography>The user has access to upload documents</Typography>
                        </Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>No</Typography>
                          <AntSwitch checked={deleteAccess} onChange={(e) => setDeleteAccess(e.target.checked)} />
                          <Typography>Yes</Typography>
                        </Stack>
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 4 }} className="flex justify-end">
                      <Button variant="contained" onClick={handleOpenDialog}>
                        Create User
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {userCreationInProgress ? (
              <Box className="">
                <DialogTitle sx={{ backgroundColor: '#4080d9', color: 'white' }} id="alert-dialog-title">
                  {'User Creation In Progress. Please wait'}
                  <ScaleLoader color="white" size={8} />
                </DialogTitle>
              </Box>
            ) : (
              <>
                <DialogTitle id="alert-dialog-title">{'Confirm User Creation'}</DialogTitle>
              </>
            )}
            <DialogContent sx={{ mt: 2 }}>
              <Typography>A user with the following details will be created below</Typography>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <TextField
                      disabled
                      fullWidth
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      value={fullName}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField disabled fullWidth id="outlined-basic" label="Email" variant="outlined" value={email} />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      type="password"
                      disabled
                      fullWidth
                      id="outlined-basic"
                      label="Temporary Password"
                      variant="outlined"
                      value={password}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      disabled
                      fullWidth
                      id="outlined-basic"
                      label="User Access"
                      variant="outlined"
                      value={status}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      disabled
                      fullWidth
                      id="outlined-basic"
                      label="User Type"
                      variant="outlined"
                      value={type}
                    />
                  </Grid>

                  <Grid item md={12} className="flex justify-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: 'gray' }}>
                        Upload Documents
                      </Typography>
                      <Typography sx={{ color: 'gray' }}>The user has access to upload documents</Typography>
                    </Box>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <AntSwitch
                        disabled
                        checked={upload}
                        onChange={(e) => setUpload(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>

                  <Grid item md={12} className="flex justify-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: 'gray' }}>
                        Download Documents
                      </Typography>
                      <Typography sx={{ color: 'gray' }}>The user has access to upload documents</Typography>
                    </Box>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <AntSwitch
                        disabled
                        checked={download}
                        onChange={(e) => setDownload(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>

                  <Grid item md={12} className="flex justify-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: 'gray' }}>
                        Delete Documents
                      </Typography>
                      <Typography sx={{ color: 'gray' }}>The user has access to upload documents</Typography>
                    </Box>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <AntSwitch disabled checked={deleteAccess} onChange={(e) => setDeleteAccess(e.target.checked)} />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={userCreationInProgress}>
                Close
              </Button>
              <Button
                onClick={handleCreateUser}
                autoFocus
                variant="contained"
                size="large"
                disabled={userCreationInProgress}
                sx={{ backgroundColor: '#47c147' }}
              >
                Agree & Create User
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : user && user.type !== 'admin' ? (
        <>
          <Box sx={{ mt: 4 }}>
            <Card>
              <CardContent>
                <Box className="flex justify-center aling-center">
                  <img
                    src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/access-denied.svg"
                    alt="access denied"
                  />
                </Box>
                <Box sx={{ pt: 4 }}>
                  <Typography variant="h5">Access Denied</Typography>
                  <Typography>
                    Kindly note that only administrators possess the authority to initiate account creation. If needed,
                    please reach out to your designated administrator for assistance.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mt: 4 }}>
            <Card>
              <CardContent>
                <Box className="flex justify-center aling-center">
                  <img
                    src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/aLL-lICESNES-USE.svg"
                    alt="licesnes used up"
                  />
                </Box>
                <Box sx={{ pt: 4 }} className="flex justify-center align-center column">
                  <Typography variant="h5">Your company has used up all avaliable licensees</Typography>
                  <Typography>Please contact us below if you need more licenses</Typography>
                  <Button size="large" sx={{ mt: 2 }} variant="contained">
                    Contact Us
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}
