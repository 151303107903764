import React, { useState } from 'react';
import Papa from 'papaparse';

function Test() {
  const [fileContent, setFileContent] = useState(null);
  const [error, setError] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setError('Please upload a valid file.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      Papa.parse(fileContent, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.errors.length > 0 || result.data.length === 0) {
            setError('Your file is not in a good format. Ensure the first row includes columns.');
            setFileContent(null);
          } else {
            setError(null);
            setFileContent(result.data);
          }
        },
      });
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleFileUpload}
      />
      {error && <p className="error">{error}</p>}
      {fileContent && (
        <div>
          <h2>File Content</h2>
          <pre>{JSON.stringify(fileContent, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Test;
