import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../hooks/useGetUser';

export function withNoAuth(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const { isLoggedIn, loading } = useGetUser();

    useEffect(() => {
      if (!loading && isLoggedIn) {
        navigate('/app');
      }
    }, [isLoggedIn, navigate, loading]);

    if (loading) return <CircularProgress />;

    return <WrappedComponent {...props} isLoggedIn={isLoggedIn} />;
  };
}
