import * as React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SegmentIcon from '@mui/icons-material/Segment';
import Nav from './mobileNav';

export default function MedAffairsMenuMobileAuth() {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <IconButton sx={{ color: 'white' }} aria-label="delete" size="large" onClick={() => setOpen(true)}>
        <SegmentIcon fontSize="inherit" />
      </IconButton>
      {/* <Button onClick={toggleDrawer(anchor, true)}>Left</Button> */}
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      {/* <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          <Box sx={{ width: '270px', mt: 3 }}>
            <Link to="/" className="flex justify-center">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/medaffairsai.png"
                alt="Logo"
                width={'70%'}
              />
            </Link>

            <List>
              <ListItem>
                <ListItemButton onClick={handleHome} disableGutters>
                  <ListItemIcon>
                    <GppGoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleTrain} disableGutters>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Train AI" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleDocuments} disableGutters>
                  <ListItemIcon>
                    <LiveHelpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Documents" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleSettings} disableGutters>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer> */}
    </div>
  );
}
