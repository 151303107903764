import Avatar from 'boring-avatars';
import { Box, Card, CardContent, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import Label from '../../../components/label/Label';

export default function Profile({ user, company }) {
  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item md={5} xs={12} sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack>
                <Box className="flex justify-end align-center">
                  <Label
                    color="success"
                    sx={{
                      zIndex: 9,
                      textTransform: 'uppercase',
                    }}
                  >
                    {user.status || 'Active'}
                  </Label>
                </Box>
                <Box sx={{ mt: 3 }} className="flex justify-center align-center">
                  <Avatar
                    size={100}
                    name={user.full_name}
                    variant="marble"
                    colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
                  />
                </Box>
              </Stack>
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="h5">{user.full_name || ''}</Typography>
                <Typography sx={{ opacity: 0.7, fontSize: '14px', margin: '4px 0px 0px' }}>{user.email}</Typography>
                <Label
                  color="default"
                  sx={{
                    zIndex: 9,
                    textTransform: 'uppercase',
                    mt: 2,
                  }}
                >
                  {user.type}
                </Label>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Box className="flex justify-between align-center">
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Upload Documents</Typography>
                    <Typography sx={{ opacity: 0.7, fontSize: '14px', margin: '1px 0px 0px' }}>
                      The user has access to upload documents
                    </Typography>
                  </Box>
                  <Box>
                    <Label
                      color={user.upload === 1 ? 'success' : 'error'}
                      sx={{
                        zIndex: 9,
                        textTransform: 'uppercase',
                        mt: 2,
                      }}
                    >
                      {user.upload === 1 ? 'Yes' : 'No'}
                    </Label>
                  </Box>
                </Box>

                <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Delete Documents</Typography>
                    <Typography sx={{ opacity: 0.7, fontSize: '14px', margin: '1px 0px 0px' }}>
                      The user has access to delete company documents
                    </Typography>
                  </Box>
                  <Box>
                    <Label
                      color={user.delete === 1 ? 'success' : 'error'}
                      sx={{
                        zIndex: 9,
                        textTransform: 'uppercase',
                        mt: 2,
                      }}
                    >
                      {user.delete === 1 ? 'Yes' : 'No'}
                    </Label>
                  </Box>
                </Box>

                <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Download Documents</Typography>
                    <Typography sx={{ opacity: 0.7, fontSize: '14px', margin: '1px 0px 0px' }}>
                      The user has access to download documents
                    </Typography>
                  </Box>
                  <Box>
                    <Label
                      color={user.download === 1 ? 'success' : 'error'}
                      sx={{
                        zIndex: 9,
                        textTransform: 'uppercase',
                        mt: 2,
                      }}
                    >
                      {user.download === 1 ? 'Yes' : 'No'}
                    </Label>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={7} xs={12} sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Box className="flex justify-between align-center">
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>Company Name</Typography>
                  <Typography>{company.company_name}</Typography>
                </Box>
                <Box>
                  <img src={company.company_logo} alt="company Logo" width={'120px'} />
                </Box>
              </Box>
              <Divider sx={{ p: 1 }} />
              <Box sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  id="filled-basic"
                  label="Full Name"
                  disabled
                  variant="outlined"
                  defaultValue={user.full_name}
                />
                <TextField
                  sx={{ mt: 3 }}
                  fullWidth
                  id="filled-basic"
                  label="Email Address"
                  disabled
                  variant="outlined"
                  defaultValue={user.email}
                />

                <TextField
                  sx={{ mt: 3 }}
                  fullWidth
                  id="filled-basic"
                  label="Date Created"
                  disabled
                  variant="outlined"
                  defaultValue={user.date_created}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
