// component
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'home',
    path: '/app',
    icon: <ManageSearchIcon />,
  },
  {
    title: 'train',
    path: '/train',
    icon: <CloudUploadIcon />,
  },
  {
    title: 'documents',
    path: '/documents',
    icon: <ArticleIcon />,
  },
  {
    title: 'settings',
    path: '/settings',
    icon: <SettingsIcon />,
  },
];

export default navConfig;
