import { CLEAR_ALL, TRAINING_COMPLETED, TRAINING_IN_PROGRESS, CLEAR_TRAINING } from '../types';

const initialState = {
  trainingInProgress: false,
  trainingFiles: [],
  trainingCompletedFiles: [],
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TRAINING:
      return {
        trainingInProgress: false,
        trainingFiles: [],
        trainingCompletedFiles: [],
      };
    case TRAINING_IN_PROGRESS:
      return {
        ...state,
        trainingInProgress: action.payload.training,
        trainingFiles: action.payload.files,
      };
    case TRAINING_COMPLETED:
      return {
        ...state,
        trainingCompletedFiles: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        trainingInProgress: false,
        trainingFiles: [],
        trainingCompletedFiles: [],
      };

    default:
      return state;
  }
}
