import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography } from '@mui/material';
import ViewTrainedDocuments from './ViewTrainedDocuments';
import AuthAppBar from '../../layouts/basic/AuthAppBar';
import useResponsive from '../../hooks/useResponsive';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import Spinner from '../../components/ui/Spinner';

export default function Documents() {
  const isDesktop = useResponsive('up', 'md');
  const { userCompanyDetails, userCompanyDetailsLoading } = useGetUserInfo();

  if (userCompanyDetailsLoading) {
    return <Spinner />;
  }
  return (
    <Box>
      <Helmet>
        <title> Documents</title>
      </Helmet>
      <Box
        className="hero-banner"
        sx={{
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: '600px',
          backgroundImage: `url('https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/bg.png')`,
          backgroundSize: 'cover',
        }}
      >
        <AuthAppBar />
        <Container sx={{ padding: '0px' }}>
          <Box sx={{ pt: 4 }}>
            <Typography sx={{ color: 'white' }} variant="h2">
              Uploaded Documents
            </Typography>
            <Typography sx={{ pt: 3, color: 'white' }} variant="h4">
              Manage and Monitor your Uploaded Documents Below
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }} className="flex justify-center align-center">
            <img
              src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/documents-ladies-talking-over-file.svg"
              alt="Documents"
              loading="lazy"
              width={isDesktop ? '35%' : '100%'}
            />
          </Box>
        </Container>
      </Box>
      <Container>
        <ViewTrainedDocuments userCompanyDetails={userCompanyDetails} />
      </Container>
    </Box>
  );
}
