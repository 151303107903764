import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyUsers } from '../store/actions/user/companyUsers';
// import { API_URL_ADMIN_USER } from "../constants/API";

export function useGetCompanyUsers() {
  const companyUsers = useSelector((state) => state.user.companyUsers);
  const companyUsersLoading = useSelector((state) => state.user.companyUsersLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getCompanyUsers());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { companyUsers, companyUsersLoading };
}
