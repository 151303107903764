import EastIcon from '@mui/icons-material/East';
import { Box, Button, Card, CardContent, Divider, Drawer, Grid, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import InitialFileUploadLoading from './InitialFileUploadLoading';
import TrainedTextDrawerContent from './TrainedTextDrawerContent';
import UploadedFileList from './UploadedFileList';

function SelectedFilesGrid({
  chunkingInProgress,
  selectedFile,
  chunkedOutputs,
  handleDeleteFile,
  handleTrainDocument,
  handleCancelTrain,
}) {
  const [state, setState] = React.useState({
    right: false,
  });
  // console.log('chunkedOutputs', chunkedOutputs);
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {chunkingInProgress && chunkedOutputs && chunkedOutputs.length < 1 ? (
        <>
          <InitialFileUploadLoading />
        </>
      ) : (
        <></>
      )}
      {chunkedOutputs && chunkedOutputs.length >= 1 ? (
        <>
          <Card>
            <CardContent>
              <>
                {chunkedOutputs && chunkedOutputs.length >= 1 ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <img
                          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/training-time.svg"
                          alt="training-time"
                          loading="lazy"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Selected Files
                        </Typography>

                        {chunkingInProgress ? (
                          <>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Skeleton variant="rounded" width={'100%'} height={80} />
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                        {chunkedOutputs.map((item, index) => (
                          <Box key={index}>
                            <UploadedFileList file={item} onDelete={handleDeleteFile} toggleDrawer={toggleDrawer} />
                          </Box>
                        ))}
                        <Box className="flex justify-end" onClick={toggleDrawer('right', true)}>
                          <Button>
                            Preview Training <EastIcon style={{ paddingLeft: '6px' }} />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {chunkedOutputs && chunkedOutputs.length >= 1 ? (
                  <>
                    <Divider style={{ border: 'solid 1px #e0e0e0', marginTop: '30px', marginBottom: '30px' }} />
                    <Box sx={{ width: '100%' }} className="flex">
                      <Button
                        sx={{ p: 3.5, width: '50%', mr: 1, fontSize: '17px' }}
                        onClick={handleCancelTrain}
                        size="large"
                        variant="outlined"
                        disabled={chunkingInProgress}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          p: 3.5,
                          fontSize: '17px',
                          width: '50%',
                          ml: 1,
                          backgroundColor: '#1a1919',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black' },
                        }}
                        onClick={handleTrainDocument}
                        size="large"
                        variant="outlined"
                        disabled={chunkingInProgress}
                      >
                        {/* Train MedAffairs AI */} Train AI
                      </Button>
                    </Box>
                    <Drawer anchor={'right'} open={openDrawer} onClose={toggleDrawer('right', false)}>
                      <TrainedTextDrawerContent trainedData={chunkedOutputs} toggleDrawer={toggleDrawer} />
                    </Drawer>
                  </>
                ) : (
                  <></>
                )}
              </>
            </CardContent>
          </Card>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SelectedFilesGrid;
