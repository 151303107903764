import React from 'react';

function VideoLoopComponent({ url }) {
  return (
    <div>
      <video autoPlay loop muted style={{ width: '100%', height: 'auto', borderRadius: '15px' }}>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoLoopComponent;
