import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from '../store/actions/user/authUser';
// import { API_URL_ADMIN_USER } from "../constants/API";

export function useGetUserInfo() {
  const userCompanyDetails = useSelector((state) => state.user.userCompanyDetails);
  const userCompanyDetailsLoading = useSelector((state) => state.user.userCompanyDetailsLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAuth() {
      try {
        await dispatch(getAuthUser());
      } catch (err) {
        console.log(err);
      }
    }
    checkAuth();
  }, []);

  return { userCompanyDetails, userCompanyDetailsLoading };
}
