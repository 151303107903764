import { Auth } from 'aws-amplify';

export async function requestCode(email) {
  try {
    const result = await Auth.forgotPassword(email);
    if (result && result.CodeDeliveryDetails) {
      return { success: true, err: null };
    }
    return { success: false, err: 'Error Generating Code' };
  } catch (error) {
    return { success: false, err: error.message || 'Unknown Error' };
  }
}
