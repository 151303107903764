import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Avatar from 'boring-avatars';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button, Grid } from '@mui/material';
import { formatString } from '../../../helpers/formatString';
import TableRowsLoader from '../../train/helpers/TableRowsLoader';
import { useGetCompanyUsers } from '../../../hooks/useGetCompanyUsers';
import Label from '../../../components/label/Label';
import UpdateUserDialog from './UpdateUserDialog';
import CreateUser from './CreateUser';

const headCells = [
  {
    id: 'full_name',
    numeric: true,
    disablePadding: false,
    label: 'Full Name',
    adminOnly: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email Address',
    adminOnly: false,
  },
  {
    id: 'document_size',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    adminOnly: false,
  },
  {
    id: 'document_type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    adminOnly: false,
  },
  {
    id: 'Upload Access',
    numeric: false,
    disablePadding: false,
    label: 'Upload',
    adminOnly: true,
  },
  {
    id: 'Download Access',
    numeric: false,
    disablePadding: false,
    label: 'Download',
    adminOnly: true,
  },
  {
    id: 'Delete Access',
    numeric: false,
    disablePadding: false,
    label: 'Delete',
    adminOnly: true,
  },
  {
    id: 'date_created',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    adminOnly: false,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { user } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          // Conditionally render the header cell based on user type and adminOnly flag
          const shouldRenderHeaderCell = user.type === 'admin' || !headCell.adminOnly;
          if (shouldRenderHeaderCell) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'left' : 'right'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}

export default function CompanyUsersTable({ company, user }) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pageValue, setPageValue] = React.useState('company-users');

  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  const { companyUsers, companyUsersLoading } = useGetCompanyUsers();

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleClick = (event, selectedUser) => {
    if (user && user.type === 'admin') {
      setSelected(selectedUser);
      setDialogOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  if (companyUsersLoading) {
    return <TableRowsLoader rowsNum={8} />;
  }

  return (
    <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
      <Grid spacing={3} container className="flex justify-center align-center">
        <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <>
            {pageValue === 'company-users' ? (
              <>
                <Box sx={{ pt: 4, pb: 3 }} className="flex justify-between align-center">
                  <Box>
                    <Typography variant="h4">{company.company_name || ''} Users</Typography>
                    <Typography sx={{ color: 'gray' }}>
                      Take charge of your company's user management and administration.
                    </Typography>
                  </Box>
                  <Button variant="contained" onClick={(e) => setPageValue('create-user')}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    New User
                  </Button>
                </Box>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  {/* <DocumentFilters page={page} onFilterApply={handleFilterDispatch} /> */}

                  <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                      <EnhancedTableHead user={user} />
                      <TableBody>
                        {companyUsers &&
                          companyUsers.users
                            .map((row, index) => {
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) => handleClick(event, row)}
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.full_name}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell>
                                    <Box className="flex align-center">
                                      <Avatar
                                        size={40}
                                        name={row.full_name}
                                        variant="marble"
                                        colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
                                      />
                                      <span style={{ paddingLeft: '10px' }}>{formatString(row.full_name, 20)}</span>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="right">{formatString(row.email, 24)}</TableCell>
                                  <TableCell align="right">
                                    <Typography sx={{ fontSize: '14px' }}>
                                      <Label
                                        sx={{
                                          zIndex: 9,
                                          textTransform: 'uppercase',
                                        }}
                                        color={row.status === 'active' ? 'success' : 'error'}
                                      >
                                        {row.status}
                                      </Label>
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Typography sx={{ fontSize: '14px' }}>{row.type}</Typography>
                                  </TableCell>

                                  {user && user.type === 'admin' ? (
                                    <>
                                      <TableCell align="right">
                                        <Label
                                          sx={{
                                            zIndex: 9,
                                            textTransform: 'uppercase',
                                          }}
                                          color={row.upload === 1 ? 'success' : 'error'}
                                        >
                                          {row.upload === 1 ? '✔' : 'X'}
                                        </Label>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Label
                                          sx={{
                                            zIndex: 9,
                                            textTransform: 'uppercase',
                                          }}
                                          color={row.download === 1 ? 'success' : 'error'}
                                        >
                                          {row.download === 1 ? '✔' : 'X'}
                                        </Label>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Label
                                          sx={{
                                            zIndex: 9,
                                            textTransform: 'uppercase',
                                          }}
                                          color={row.delete === 1 ? 'success' : 'error'}
                                        >
                                          {row.delete === 1 ? '✔' : 'X'}
                                        </Label>
                                      </TableCell>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <TableCell align="right">
                                    <Typography sx={{ fontSize: '14px' }}>{row.date_created}</Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    {user && user.type === 'admin' ? (
                                      <>
                                        <IconButton>
                                          <EditIcon />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                            .reverse()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[2]}
                    component="div"
                    count={companyUsers.users ? companyUsers.users.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
                <FormControlLabel
                  control={<Switch checked={dense} onChange={handleChangeDense} />}
                  label="Dense padding"
                />
                <UpdateUserDialog selectedUser={selected} dialogOpen={dialogOpen} closeDialog={closeDialog} />
              </>
            ) : pageValue === 'create-user' ? (
              <>
                <CreateUser
                  companyUsers={companyUsers}
                  user={user}
                  company={company}
                  pageValue={pageValue}
                  setPageValue={setPageValue}
                />
              </>
            ) : (
              <></>
            )}
            <></>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}
