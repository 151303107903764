export function loginValidate(email, password) {
  const error = { message: '' };

  // Check if email and password exist
  if (!email || !password) {
    error.message = 'Email and password are required';
    return error;
  }

  if (password.length <= 5) {
    error.message = 'Password must be greater than 5 characters';
    return error;
  }

  // Check if email is in a valid format
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) {
    error.message = 'Email is not in a valid format';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
