import { Box, Button } from '@mui/material';
import CustomDropZone from './CustomDropZone';

export default function TrainDocuments() {
  return (
    <Box sx={{ pt: 5, pb: 5 }}>
      <Box className="flex justify-center align-center">
        <CustomDropZone />
      </Box>
    </Box>
  );
}
