import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { getUserInfo } from '../../../store/actions/user/unAuthUser';

export async function signInUser(email, password, dispatch) {
  try {
    // Check if user exist in cognito
    const user = await Auth.signIn(email, password);
    if (user) {
      const userInfoResponse = await getUserInfo(email, dispatch);
      // console.log('user info response', userInfoResponse);
      if (userInfoResponse && userInfoResponse.success === true) {
        return { success: true, user, userInfo: userInfoResponse.userInfo };
      }
      await Auth.signOut();
      return { success: false, user: null, userInfo: null, error: { message: userInfoResponse.message } };
    }
    return { success: false, user: null, userInfo: null, error: { message: 'User Not Found! Please try again' } };
  } catch (error) {
    console.log('error signing in', error);
    return { success: false, error };
  }
}
