import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, IconButton, Box, Button, Card, Chip, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useEffect, useState } from 'react';
import CompanyUsersButton from './CompanyUsersButton';
import SelectDates from './SelectDates';
import SelectTypes from './Types';
import useResponsive from '../../../hooks/useResponsive';

export default function DocumentFilters({ page, onFilterApply }) {
  const [startDate, setStartDate] = useState(''); // Store selected user IDs here
  const [endDate, setEndDate] = useState(''); // Store selected user IDs here
  const [typesFinal, setTypesFinal] = useState([]); // Store selected user IDs here
  const [selectedUsersFinal, setSelectedUsersFinal] = useState([]); // Store selected user IDs here
  const [usersRemove, setUsersRemove] = useState({}); // Store selected user IDs here
  const [typeItemRemove, setTypeItemRemove] = useState({}); // Store selected user IDs here
  const [dateRemove, setDateRemove] = useState(false); // Store selected user IDs here
  const [removeAll, setRemoveAll] = useState(false); // Store selected user IDs here
  const [search, setSearch] = useState(''); // Store selected user IDs here
  const [searchValue, setSearchValue] = useState(''); // Store selected user IDs here
  const isDesktop = useResponsive('up', 'md');
  // Monitor changes in filter values
  useEffect(() => {
    const payload = {
      users: selectedUsersFinal,
      types: typesFinal,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
      endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
      query: searchValue,
    };

    // Call the onFilterApply function whenever any of the filter values change
    onFilterApply(payload);
  }, [selectedUsersFinal, typesFinal, startDate, endDate, searchValue]);

  const handleRemoveUsersItem = (item) => {
    setSelectedUsersFinal((prevSelectedUsersFinal) => prevSelectedUsersFinal.filter((user) => user !== item));
    setUsersRemove(item);
  };

  const handleRemoveAllFilters = () => {
    setSelectedUsersFinal([]);
    setTypesFinal([]);
    setStartDate('');
    setEndDate('');
    setSearch('');
    setSearchValue('');
    setRemoveAll(true);
    onFilterApply({});
  };

  const handleRemoveTypeItem = (item) => {
    setTypesFinal((prevSelectedTypes) => prevSelectedTypes.filter((user) => user !== item));
    setTypeItemRemove(item);
  };
  const handleRemoveDateItem = () => {
    setStartDate('');
    setEndDate('');
    setDateRemove(true);
  };

  const handleRemoveSearch = () => {
    setSearch('');
    setSearchValue('');
  };

  const handleSearch = () => {
    setSearchValue(search);
  };

  return (
    <>
      <Box sx={{ p: 2, borderRadius: '15px' }}>
        {isDesktop ? (
          <Box className="flex justify-between align-center">
            <Box sx={{ width: '30%', pr: 2 }}>
              <form noValidate>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  type="search"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                />
              </form>
            </Box>
            <Box className="flex justify-center align-center">
              <CompanyUsersButton
                usersRemove={usersRemove}
                setUsersRemove={setUsersRemove}
                setSelectedUsersFinal={setSelectedUsersFinal}
                selectedUsersFinal={selectedUsersFinal}
                removeAll={removeAll}
                setRemoveAll={setRemoveAll}
              />
              <SelectDates
                dateRemove={dateRemove}
                setDateRemove={setDateRemove}
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setRemoveAll={setRemoveAll}
              />
              <SelectTypes
                typeItemRemove={typeItemRemove}
                setTypeItemRemove={setTypeItemRemove}
                removeAll={removeAll}
                typesFinal={typesFinal}
                setTypesFinal={setTypesFinal}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ width: '100%' }}>
              <form noValidate>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  type="search"
                  variant="outlined"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                />
              </form>
            </Box>
            <Box className="flex justify-center align-center" sx={{ mt: 2 }}>
              <CompanyUsersButton
                title="Users"
                usersRemove={usersRemove}
                setUsersRemove={setUsersRemove}
                setSelectedUsersFinal={setSelectedUsersFinal}
                selectedUsersFinal={selectedUsersFinal}
                removeAll={removeAll}
                setRemoveAll={setRemoveAll}
              />
              <SelectDates
                title="Range"
                dateRemove={dateRemove}
                setDateRemove={setDateRemove}
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setRemoveAll={setRemoveAll}
              />
              <SelectTypes
                title="Types"
                typeItemRemove={typeItemRemove}
                setTypeItemRemove={setTypeItemRemove}
                removeAll={removeAll}
                typesFinal={typesFinal}
                setTypesFinal={setTypesFinal}
              />
            </Box>
          </>
        )}
      </Box>

      {(searchValue && searchValue.length >= 1) ||
      (selectedUsersFinal && selectedUsersFinal.length >= 1) ||
      (typesFinal && typesFinal.length >= 1) ||
      (startDate && endDate) ? (
        <>
          <Box sx={{ pl: 3, pr: 3, pb: 1 }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Selected Filters</Typography>
          </Box>
          <Box className="flex" sx={{ pl: 3, pr: 3, pb: 2, flexWrap: 'wrap' }}>
            <Box>
              {searchValue && searchValue.length >= 1 ? (
                <>
                  <Card className="flex justify-center align-center" sx={{ border: 'dashed 1px', pr: 0.5 }}>
                    <Typography sx={{ pl: 1, pr: 1, fontSize: '14px', fontWeight: 'bold' }}>Search: </Typography>

                    <Box sx={{ p: 0.3, pt: 1, pb: 1 }}>
                      <Chip variant="outlined" onDelete={handleRemoveSearch} label={searchValue || ''} />
                    </Box>
                  </Card>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ ml: 1 }}>
              {selectedUsersFinal && selectedUsersFinal.length >= 1 ? (
                <Card className="flex justify-center align-center" sx={{ border: 'dashed 1px', pr: 0.5 }}>
                  <Typography sx={{ pl: 1, pr: 1, fontSize: '14px', fontWeight: 'bold' }}>Users: </Typography>
                  {selectedUsersFinal.map((item, index) => (
                    <>
                      <Box sx={{ p: 0.3, pt: 1, pb: 1 }}>
                        <Chip
                          variant="outlined"
                          onDelete={() => handleRemoveUsersItem(item)}
                          label={item.full_name}
                          key={index}
                        />
                      </Box>
                    </>
                  ))}
                </Card>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ ml: 1 }}>
              {typesFinal && typesFinal.length >= 1 ? (
                <Card className="flex justify-center align-center" sx={{ border: 'dashed 1px', pr: 0.5 }}>
                  <Typography sx={{ pl: 1, pr: 1, fontSize: '14px', fontWeight: 'bold' }}>File Types: </Typography>
                  {typesFinal.map((item, index) => (
                    <>
                      <Box sx={{ p: 0.3, pt: 1, pb: 1 }}>
                        <Chip
                          variant="outlined"
                          onDelete={() => handleRemoveTypeItem(item)}
                          label={item.title}
                          key={index}
                        />
                      </Box>
                    </>
                  ))}
                </Card>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ ml: 1 }}>
              {startDate && endDate ? (
                <Card className="flex justify-center align-center" sx={{ border: 'dashed 1px', pr: 0.5 }}>
                  <Typography sx={{ pl: 1, pr: 1, fontSize: '14px', fontWeight: 'bold' }}>Date Range: </Typography>
                  <Box sx={{ p: 0.3, pt: 1, pb: 1 }}>
                    <Chip
                      variant="outlined"
                      onDelete={handleRemoveDateItem}
                      label={`(${startDate.format('YYYY-MM-DD')}) - (${endDate.format('YYYY-MM-DD')})`}
                    />
                  </Box>
                </Card>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box sx={{ pl: 3, pr: 3, pb: 2 }}>
            <Button
              startIcon={<DeleteForeverIcon />}
              onClick={handleRemoveAllFilters}
              sx={{ backgroundColor: '#ff563014', color: '#ff5630' }}
            >
              Clear Filters
            </Button>
            {/* <Button endIcon={<SendIcon />} sx={{ ml: 1, backgroundColor: '#e9ebec' }} onClick={handleApplyFilters} vavi>
              Apply Filters
            </Button> */}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
