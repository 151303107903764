import { toast } from 'react-hot-toast';

export const randomizeFileName = (selected) => {
  const filesWithRandomNames = selected.map((file) => {
    const lastPeriodIndex = file.name.lastIndexOf('.');
    if (lastPeriodIndex === -1 || lastPeriodIndex === 0) {
      // If there is no period in the file name or it's at the beginning, handle this case accordingly.
      toast.error(
        `${file.name} has an invalid format. Training this document will result in an error. Please Remove this File.`
      );
      return file; // Return the original file without modification
    }

    const fileNameWithoutExtension = file.name.substring(0, lastPeriodIndex);
    const fileExtension = file.name.substring(lastPeriodIndex + 1);
    const randomNumber = Math.floor(Math.random() * 1000);

    // Remove all dots from fileNameWithoutExtension
    const cleanedFileName = fileNameWithoutExtension.replace(/\./g, '');

    const fileNameWithRandomNumber = `${cleanedFileName}_${randomNumber}.${fileExtension}`;

    return new File([file], fileNameWithRandomNumber, { type: file.type });
  });
  return filesWithRandomNames;
};
