function getFileSize(sizeInBytes) {
  const sizeUnits = ['Bytes', 'KB', 'MB', 'GB'];
  let size = sizeInBytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < sizeUnits.length - 1) {
    size /= 1024;
    unitIndex += 1;
  }

  return `${size.toFixed(2)} ${sizeUnits[unitIndex]}`;
}

export default getFileSize;
