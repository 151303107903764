import Label from '../../../components/label/Label';

export default function InternalExternal({ answer }) {
  return answer && answer.internalExternal === false ? (
    <>
      <Label
        variant="filled"
        sx={{
          ml: 2,
          zIndex: 9,
          textTransform: 'uppercase',
        }}
      >
        Internal Output
      </Label>
    </>
  ) : answer.internalExternal === true ? (
    <>
      <Label
        variant="filled"
        sx={{
          ml: 2,
          zIndex: 9,
          textTransform: 'uppercase',
        }}
      >
        External Output
      </Label>
    </>
  ) : (
    <></>
  );
}
