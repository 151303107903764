import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Typography } from '@mui/material';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';

export default function TypesOfQuestions() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button sx={{ mt: 2 }} onClick={toggleDrawer(anchor, true)}>
            Types of Questions to Ask
          </Button> */}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <Box sx={{ width: '500px', p: 2 }}>
              <Typography variant="h3">Types of Questions to Ask</Typography>
              <Box sx={{ pt: 3 }}>
                <Box sx={{ display: 'flex', mt: 3 }}>
                  <Filter1Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    How does the process of glycosylation impact the function and stability of therapeutic monoclonal
                    antibodies?
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ display: 'flex' }}>
                  <Filter2Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    After approval of a “stand-alone” Biologics License Application, the product is approved as?
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ display: 'flex' }}>
                  <Filter3Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    When is a biologic license application (BLA) generally submitted in relation to an IND application?{' '}
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />

                <Box sx={{ display: 'flex' }}>
                  <Filter4Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    After approval of a “stand-alone” Biologics License Application, the product is approved as?
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />

                <Box sx={{ display: 'flex' }}>
                  <Filter5Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    Explain the concept of protein aggregation in the context of biologics manufacturing?{' '}
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />

                <Box sx={{ display: 'flex' }}>
                  <Filter6Icon style={{ fontSize: '40px' }} />
                  <Typography sx={{ ml: 2 }}>
                    Describe the factors that contribute to the degradation of biologics over time and explain how
                    formulation and packaging can help mitigate these stability concerns.{' '}
                  </Typography>
                </Box>

                <Typography sx={{ mt: 4 }}>and much more...</Typography>
              </Box>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
