import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getCompanyUsers } from '../../../store/actions/user/companyUsers';
import { formatString } from '../../../helpers/formatString';
import { useGetCompanyUsers } from '../../../hooks/useGetCompanyUsers';

export default function CompanyUsersButton({
  removeAll,
  setRemoveAll,
  usersRemove,
  setUsersRemove,
  selectedUsersFinal,
  setSelectedUsersFinal,
}) {
  const { companyUsers, companyUsersLoading } = useGetCompanyUsers();
  const [open, setOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUserSelect = (item) => {
    if (selectedUsers && selectedUsers.includes(item)) {
      setSelectedUsers(selectedUsers.filter((user) => user !== item));
    } else {
      setSelectedUsers([...selectedUsers, item]);
    }
  };

  const handleRemoveItem = (item) => {
    if (selectedUsers && selectedUsers.includes(item)) {
      setSelectedUsers(selectedUsers.filter((user) => user !== item));
    }
  };

  const handleRemoveAll = () => {
    setSelectedUsers([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    setSelectedUsersFinal(selectedUsers);
    setOpen(false);
  };

  useEffect(() => {
    if (usersRemove) {
      if (selectedUsers && selectedUsers.includes(usersRemove)) {
        setSelectedUsers(selectedUsers.filter((user) => user !== usersRemove));
      }
    }
    return () => {
      setUsersRemove({});
    };
  }, [usersRemove]);

  useEffect(() => {
    if (removeAll) {
      setSelectedUsers([]);
    }
    return () => {
      setRemoveAll(false);
    };
  }, [removeAll]);

  if (companyUsersLoading) {
    return <Skeleton variant="rounded" width={100} height={40} />;
  }

  return (
    <Box>
      <Button endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen}>
        Users
      </Button>
      <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <Box>
          <DialogTitle>{'Select Documents Uploaded by Users Below'}</DialogTitle>
          <DialogContent>
            {companyUsersLoading ? (
              <>
                <Skeleton variant="rectangular" width={410} height={80} />
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item md={12} sx={{ mt: 2, width: '100%' }}>
                    <TextField
                      id="outlined-basic"
                      label="Search User"
                      variant="outlined"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Grid>
                  {selectedUsers && selectedUsers.length >= 1 ? (
                    <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                      <Card sx={{ width: '100%', border: 'dashed 1px black' }}>
                        <Box sx={{ p: 2 }}>
                          <Box className="flex justify-between align-center">
                            <Typography sx={{ fontWeight: 'bold' }}>Selected Users</Typography>
                            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleRemoveAll}>
                              Clear All
                            </Button>
                          </Box>
                          {selectedUsers.map((item, index) => (
                            <Chip
                              variant="outlined"
                              onDelete={() => handleRemoveItem(item)}
                              sx={{ mt: 1.5, ml: 1 }}
                              label={item.full_name}
                              key={index}
                            />
                          ))}
                        </Box>
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    md={12}
                    className="hide-scrollbar"
                    sx={{
                      mt: 2,
                      height: '300px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Grid container spacing={1}>
                      {companyUsers &&
                        companyUsers.users.length >= 1 &&
                        companyUsers.users
                          .filter((item) => item.full_name.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((item, index) => (
                            <Grid item md={4} xs={12} key={index} sx={{ mt: 1, width: '100%' }}>
                              <Card
                                sx={{
                                  width: '100%',
                                  backgroundColor:
                                    selectedUsers && selectedUsers.includes(item) ? '#dddddd' : 'inherit', // Use 'inherit' for the default background color
                                }}
                              >
                                <CardActionArea onClick={(e) => handleUserSelect(item)}>
                                  <Box sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                      {formatString(item.full_name, 13)}
                                    </Typography>
                                    <Typography sx={{ fontSize: '13px' }}>{formatString(item.email, 18)}</Typography>
                                  </Box>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleSelect}
              variant={selectedUsers && selectedUsers.length >= 1 ? 'contained' : 'outlined'}
            >
              Select
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
