// React
import { useState } from 'react';
// @mui
import { toast } from 'react-hot-toast';
import { Stack, styled, TextField, Button, Alert, Typography, InputAdornment, IconButton } from '@mui/material';
// Icons
// Hooks
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate } from 'react-router-dom';
import { requestCode } from '../../hooks/useRequestCode';
import { updateCode } from '../../hooks/useUpdateCode';
// Helpers

// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleCode = async (event) => {
    event.preventDefault();
    if (code && code.length >= 3) {
      const { success, error } = await updateCode(email, code, password);
      if (success) {
        toast.success('Password Successfully Reset! Please Login');
        navigate('/login');
      } else {
        toast.error(error);
      }
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    if (email && email.length >= 1) {
      const { success, err } = await requestCode(email);
      if (success) {
        setIsCodeSent(true);
      } else {
        toast.error(err);
      }
    } else {
      toast.error('Please enter a valid email address');
    }
    setError('');
  };

  return (
    <>
      {!isCodeSent ? (
        <>
          <StyledContent>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
              <Link
                variant="h7"
                style={{ textDecoration: 'none', color: 'black' }}
                to="/login"
                className="flex align-center"
              >
                <KeyboardBackspaceIcon />
                <Typography sx={{ ml: 1 }}>Login</Typography>
              </Link>
            </Stack>
            <Typography variant="h4" gutterBottom>
              Forgot your Password? 😔
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              No worries, please provide your email below and look out for a confirmation code in your email
            </Typography>

            <Stack spacing={3}>
              <TextField required name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />
              {error.message && <Alert severity="error">{error.message}</Alert>}

              <Button fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                Request Code
              </Button>
            </Stack>
          </StyledContent>
        </>
      ) : (
        <>
          <StyledContent>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
              <Link
                variant="h7"
                style={{ textDecoration: 'none', color: 'black' }}
                to="/login"
                className="flex align-center"
              >
                <KeyboardBackspaceIcon />
                <Typography sx={{ ml: 1 }}>Login</Typography>
              </Link>
            </Stack>
            <Typography variant="h4" gutterBottom>
              Check your Email for a Code
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              A confirmation code has been sent to your email, please enter that below to get reset your password
            </Typography>

            <Stack spacing={3}>
              <TextField required name="text" label="Verification Code" onChange={(e) => setCode(e.target.value)} />
              <TextField
                required
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <VisibilityOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error.message && <Alert severity="error">{error.message}</Alert>}

              <Button fullWidth size="large" type="submit" variant="contained" onClick={handleCode}>
                Confirm Code
              </Button>
            </Stack>
          </StyledContent>
        </>
      )}
    </>
  );
}
