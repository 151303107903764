import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/dashboard/index';
import UnAuthLayout from './layouts/unAuth/UnAuthLayout';
import Home from './pages/home';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/forgotPassword';
import WhyMedAffairsAI from './pages/whyMedAffairsAI';
import Benefits from './pages/benefits';
import QuestionsAnswersPage from './pages/questions';
import Demo from './pages/demo';
import Train from './pages/train';
import Documents from './pages/documents';
import Settings from './pages/settings';
import Test from './pages/test';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/why-medaffairs-ai', element: <WhyMedAffairsAI /> },
    { path: '/app', element: <DashboardAppPage /> },
    { path: '/benefits', element: <Benefits /> },
    { path: '/questions', element: <QuestionsAnswersPage /> },
    { path: '/demo', element: <Demo /> },
    { path: '/train', element: <Train /> },
    { path: '/documents', element: <Documents /> },
    { path: '/settings', element: <Settings /> },
    { path: '/test', element: <Test /> },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
