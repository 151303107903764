import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const LogoBlack = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/pacs_ai/priorauthAI_blue.png';

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      <img src={logo} alt="acma-logo" width={isDesktop ? '85%' : '100%'} />
    </Link>
  );
});

LogoBlack.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoBlack;
