import { Box, Button, Card, CardContent, Chip, Divider, Grid, TextField, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CancelIcon from '@mui/icons-material/Cancel';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { RiseLoader } from 'react-spinners';
import { toast } from 'react-hot-toast';
import { createElement, useEffect, useState } from 'react';
import TypesOfQuestions from './TypesOfQuestions';
import { removeConversation, searchChat } from '../../../store/actions/search';
import DataSwitcher from './DataSwitcher';
import { UniqueFileList } from '../../../helpers/getUniqueFiles';
import Label from '../../../components/label/Label';
import InternalExternal from './InternalExternal';
import Resources from './Resources';

export default function SearchBoxes({ userCompanyDetails }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [answer, setAnswer] = useState([]);
  const dispatch = useDispatch();
  const searchOutput = useSelector((state) => state.search.searchOutput);
  const searchOutputLoading = useSelector((state) => state.search.searchOutputLoading);

  const handleSearch = () => {
    if (!searchQuery || searchQuery.length < 1 || isSwitchOn === null) {
      toast.error('Please ask a valid question or make sure Internal or External is selected');
    } else {
      dispatch(searchChat(searchQuery, isSwitchOn, userCompanyDetails));
    }
  };

  useEffect(() => {
    const sorryText = 'Sorry';
    if (searchOutput && searchOutput.answer.length >= 2 && searchOutput.answer.includes(sorryText)) {
      const outputWithoutSorry = searchOutput.answer.replace(sorryText, '');
      const sanitizedOutput = DOMPurify.sanitize(outputWithoutSorry);
      const modifiedHtml = sanitizedOutput
        .replace(/<(div|p)/g, '<$1 style="padding-bottom: 10px;">')
        .replace(/<\/(div|p)/g, '</$1>');

      const newAnswer = {
        output: modifiedHtml,
        status: true,
        files: [],
        internalExternal: null,
      };
      setAnswer(newAnswer);
    } else if (searchOutput && searchOutput.answer) {
      const uniqueFiles = UniqueFileList(searchOutput.files);
      const sanitizedOutput = DOMPurify.sanitize(searchOutput.answer);
      const modifiedHtml = sanitizedOutput.replace(/<div([^>]*?)>/g, '<div$1 style="padding-bottom: 10px;">');

      const newAnswer = {
        output: modifiedHtml,
        status: false,
        files: uniqueFiles,
        internalExternal: searchOutput.internal_or_external,
      };
      setAnswer(newAnswer);
    }
  }, [searchOutput]);

  const handleReset = () => {
    setSearchQuery('');
    dispatch(removeConversation());
    setAnswer([]);
  };

  useEffect(() => {
    return () => {
      dispatch(removeConversation());
      setAnswer([]);
    };
  }, []);

  return (
    <Grid container spacing={3} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {userCompanyDetails && userCompanyDetails.company && userCompanyDetails.company.company_access === 'demo' ? (
        <>
          <Grid item md={10}>
            <Card sx={{ color: 'white', backgroundColor: '#10243a' }}>
              <CardContent>
                <Typography variant="h5">👋 Field Reimbursement & Prior Auth AI Demo</Typography>
                <Typography>
                  Welcome to the trial demo! You're currently exploring a limited version of our software. Upgrade to
                  the full version for access to all features and functionality.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item md={5} sx={{ width: '100%' }}>
        <Card sx={{ minHeight: '400px', height: 'auto' }}>
          <CardContent>
            <Box sx={{ height: '50px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box className="flex justify-center align-center">
                  <AutoAwesomeIcon style={{ fontSize: '30px' }} />
                  <Typography variant="h5" sx={{ ml: 2 }}>
                    Ask Question ...
                  </Typography>
                </Box>
                {searchQuery && searchQuery.length >= 1 ? (
                  <Box>
                    <Button onClick={handleReset} sx={{ backgroundColor: '#051d23' }} variant="contained">
                      <CancelIcon style={{ paddingRight: '5px' }} /> Clear
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ mt: 2, width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  id="outlined-multiline-static"
                  placeholder="Type or paste your question here..."
                  multiline
                  rows={5}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <DataSwitcher isSwitchOn={isSwitchOn} setIsSwitchOn={setIsSwitchOn} />
                <Button
                  variant="contained"
                  fullWidth
                  disabled={searchOutputLoading}
                  size={'large'}
                  onClick={handleSearch}
                  sx={{ backgroundColor: '#051d23', borderRadius: '13px' }}
                >
                  Ask Question
                </Button>
              </Box>
              <Box>
                <TypesOfQuestions />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={5} sx={{ width: '100%' }}>
        <Card sx={{ minHeight: '400px', overflowY: 'scroll' }}>
          <CardContent>
            <Box sx={{ height: '50px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box className="flex justify-center align-center">
                  <BubbleChartIcon />
                  <Typography variant="h5" sx={{ ml: 2 }}>
                    AI Outputs
                  </Typography>
                </Box>
                <InternalExternal answer={answer} />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box>
                {searchOutputLoading ? (
                  <>
                    <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <RiseLoader color="#014459" size={8} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.output) }} />
                      <Resources answer={answer} isSwitchOn={isSwitchOn} user={userCompanyDetails.user} />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
