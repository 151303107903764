import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import Label from '../../../components/label/Label';
import { downloadFile } from '../../../store/actions/documents/download';
import { formatString } from '../../../helpers/formatString';

export default function Resources({ answer, isSwitchOn, user }) {
  const dispatch = useDispatch();
  const handleClick = (item) => {
    dispatch(downloadFile(item));
  };
  return (
    <>
      {isSwitchOn === 'false' && user.download === 1 ? (
        <>
          <Box sx={{ pt: 2, pb: 2 }}>
            {answer.files && answer.files.length >= 1 ? (
              <>
                <Typography sx={{ mb: 1 }}>Resources</Typography>
                {answer.files.map((item, index) => (
                  <Label
                    key={index}
                    variant="filled"
                    onClick={() => handleClick(item)}
                    sx={{
                      mt: 1.5,
                      ml: index === 0 ? 0 : 1.5,
                      zIndex: 9,
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                    }}
                  >
                    {formatString(item, 20)}
                  </Label>
                ))}
              </>
            ) : (
              <></>
            )}
          </Box>
        </>
      ) : user.download === 0 ? (
        <>
          <Box sx={{ pt: 2, pb: 2 }}>
            {answer.files && answer.files.length >= 1 ? (
              <>
                <Typography sx={{ mb: 1 }}>Resources</Typography>
                {answer.files.map((item, index) => (
                  <Label
                    key={index}
                    variant="filled"
                    sx={{
                      mt: 1.5,
                      ml: index === 0 ? 0 : 1.5,
                      zIndex: 9,
                      textTransform: 'uppercase',
                    }}
                  >
                    {formatString(item, 20)}
                  </Label>
                ))}
              </>
            ) : (
              <></>
            )}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
