// Icons
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ScienceIcon from "@mui/icons-material/Science";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";
import PolicyIcon from "@mui/icons-material/Policy";
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Welcome",
    path: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "What is CNMAP™?",
    path: "/cnmap-program-information",
    icon: <InfoIcon />,
  },
  {
    title: "Enrollment Information",
    path: "/cnmap-enrollment-criteria",
    icon: <VerifiedIcon />,
  },
  {
    title: "Payment",
    path: "/cnmap-payment",
    icon: <PaidIcon />,
  },
  {
    title: "Promote Your Certification",
    path: "/cnmap-promote-certification",
    icon: <StarIcon />,
  },
  {
    title: "Steering Committee ",
    path: "/steering-committee",
    icon: <AutoAwesomeMosaicIcon />,
  },
  {
    title: "FAQs",
    path: "/cnmap-faqs",
    icon: <LiveHelpIcon />,
  },
];

export default navConfig;
