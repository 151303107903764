const Features = [
  {
    title: 'Custom made field reimbursement/prior auth machine learning tool',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/light+bulb.svg',
  },
  {
    title: 'Easy to navigate & familiar interface',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/ui-ux.svg',
  },
  {
    title: 'Automatic updates & integrations',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/update-cloud.svg',
  },
  {
    title: 'Manage huge quantities of data more effectively',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/ai-text.svg',
  },
];

export default Features;
