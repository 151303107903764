import { Box, Typography } from '@mui/material';
import VideoLoopComponent from './VideoLoopComponent';

export default function Tab2() {
  return (
    <Box sx={{ mt: 5 }}>
      <Box className="flex justify-center align-center column">
        <Typography variant="h2">External Data Search</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
          Access a wealth of knowledge in field reimbursement & prior Auth by simply posing your questions. Our system
          is trained with ACMA's comprehensive compendium of field reimbursement & prior auth data, ensuring you get the
          answers you need.
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <VideoLoopComponent
          url={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/pacs_ai/external+(online-video-cutter.com).mp4'
          }
        />
      </Box>
    </Box>
  );
}
