import { Box, Card, CardContent, Chip, IconButton, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function TrainedTextDrawerContent({ trainedData, toggleDrawer }) {
  return (
    <Box sx={{ maxWidth: '450px', p: 2 }}>
      <Box className="flex">
        <IconButton onClick={toggleDrawer('right', false)}>
          <HighlightOffIcon />
        </IconButton>
        <Typography variant="h4">Trained Text Documents</Typography>
      </Box>

      {trainedData && trainedData.length >= 1 ? (
        <>
          {trainedData.map((item) => (
            <>
              <Box sx={{ mt: 2 }}>
                <Chip label={item.file.name} />
              </Box>
            </>
          ))}
          {trainedData.map((item) => (
            <>
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Typography variant="h5">{item.file.name}</Typography>
                  {item.chunks && item.chunks.length >= 1 ? (
                    <>
                      {item.chunks.map((item2) => (
                        <>
                          <Typography>{item2}</Typography>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Typography>No text found from your document</Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          ))}
        </>
      ) : (
        <>
          <Typography>
            Unable to detect any text from the documents you are trying to train! Please try again or contact us
          </Typography>
        </>
      )}
    </Box>
  );
}
