import { Box, Typography } from '@mui/material';
import VideoLoopComponent from './VideoLoopComponent';

export default function Tab5() {
  return (
    <Box sx={{ mt: 5 }}>
      <Box className="flex justify-center align-center column">
        <Typography variant="h2">Access Control</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
          Effortlessly manage your users and have complete authority over all aspects of your Field Reimbursement &
          Prior Auth AI. This includes creating and removing users, as well as adjusting access permissions to suit your
          requirements.
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <VideoLoopComponent
          url={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/videos/settings.mp4'}
        />
      </Box>
    </Box>
  );
}
