import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

export default function DataSwitcher({ isSwitchOn, setIsSwitchOn }) {
  useEffect(() => {
    const switchValue = localStorage.getItem('searchDataType');
    setIsSwitchOn(switchValue);
  });

  const handleChange = (event, newValue) => {
    localStorage.setItem('searchDataType', newValue);
    setIsSwitchOn(newValue);
  };

  return (
    <>
      <Box
        sx={{
          mb: 2,
          p: 1,
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'end',
          flexDirection: 'column',
        }}
      >
        {/* <Typography sx={{ fontSize: '14px' }}>Extract answers from</Typography> */}
        <ToggleButtonGroup color="primary" value={isSwitchOn} exclusive onChange={handleChange} aria-label="Platform">
          <ToggleButton
            value="false" // Use strings "false" and "true"
            disabled={localStorage.getItem('searchDataType') === 'false'} // Compare to string
            style={{
              backgroundColor: localStorage.getItem('searchDataType') === 'false' ? '#54d62c29' : '', // Compare to string
              color: localStorage.getItem('searchDataType') === 'false' ? '#229A16' : '', // Compare to string
            }}
          >
            Internal Data
          </ToggleButton>
          <ToggleButton
            value="true" // Use strings "false" and "true"
            disabled={localStorage.getItem('searchDataType') === 'true'} // Compare to string
            style={{
              backgroundColor: localStorage.getItem('searchDataType') === 'true' ? '#54d62c29' : '', // Compare to string
              color: localStorage.getItem('searchDataType') === 'true' ? '#229A16' : '', // Compare to string
            }}
          >
            External Data
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
}
