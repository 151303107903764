import axios from 'axios';
import { toast } from 'react-hot-toast';
import { EXTERNAL_DB_USER } from '../../types';
import { UN_AUTH_USER } from '../../API';

export async function getUserInfo(email, dispatch) {
  try {
    // Make an API call to your external database to retrieve user info
    const response = await axios.get(UN_AUTH_USER, {
      params: {
        email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const userInfo = response.data;
      dispatch({
        type: EXTERNAL_DB_USER,
        payload: userInfo,
      });
      return { success: true, userInfo };
    }

    return { success: false, userInfo: null };
  } catch (error) {
    console.log('error getting user info', error);
    return { success: false, message: error.response.data.message };
  }
}
