import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Stack, CircularProgress } from '@mui/material';
// mock
// hooks
import { Auth } from 'aws-amplify';
import Avatar from 'boring-avatars';
import { useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import useResponsive from '../../../hooks/useResponsive';
// components

import Logo from '../../../components/logo/LogoBlack';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';

import { clearALLStates } from '../../../store/actions/clear';
import { useGetCompanyUsers } from '../../../hooks/useGetCompanyUsers';
import { formatString } from '../../../helpers/formatString';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userFullName, setUserFullName] = useState(null);

  useEffect(() => {
    async function fetchUserAttributes() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // Access user attributes, assuming 'name' is a custom attribute containing the full name.
        const fullName = user.attributes.name;

        setUserFullName(fullName);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    }

    fetchUserAttributes();
  }, []);

  const logout = async () => {
    try {
      await Auth.signOut();
      dispatch(clearALLStates());
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar
              size={40}
              name={userFullName || ''}
              variant="marble"
              colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
            />

            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontWeight: 800 }}>Welcome, </Typography>
              <Typography sx={{ color: 'text.primary', fontSize: '14px' }}>
                {formatString(userFullName || '', 15)}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <LogoutIcon />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Logout
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Have a great day!
            </Typography>
          </Box>

          <Button variant="contained" onClick={logout}>
            Logout
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
