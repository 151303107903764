import { combineReducers } from 'redux';
import searchReducers from './searchReducers';
import userReducers from './userReducers';
import trainingReducers from './trainingReducers';
import documentReducers from './documentReducers';

export default combineReducers({
  search: searchReducers,
  user: userReducers,
  training: trainingReducers,
  documents: documentReducers,
});
