import { Auth } from 'aws-amplify';
import axios from 'axios';
import { GET_COMPANY_USERS } from '../../types';
import { COMPANY_USERS_API } from '../../API';

export const getCompanyUsers = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    // Make an API call to your external database to retrieve user info
    const response = await axios.get(COMPANY_USERS_API, {
      params: {
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log(response.data);
    if (response.status === 200) {
      const users = response.data;
      dispatch({
        type: GET_COMPANY_USERS,
        payload: users,
      });
    }
  } catch (error) {
    console.log('error getting user info', error);
  }
};
