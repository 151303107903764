export function updateUserValidate(email, fullName, userType, downloadValue, uploadValue, deleteValue, userStatus) {
  const error = { message: '' };

  // Check if email and password exist
  if (!fullName && fullName.length <= 1) {
    error.message = 'Full name is required!';
    return error;
  }

  if (!userType && userType.length <= 1) {
    error.message = 'Please select the type of user to either user or admin';
    return error;
  }

  if (!downloadValue) {
    error.message = 'Download access is missing!';
    return error;
  }

  if (!uploadValue) {
    error.message = 'Upload access is missing!';
    return error;
  }

  if (!deleteValue) {
    error.message = 'Delete access is missing!';
    return error;
  }

  if (!userStatus) {
    error.message = 'The user status is missing!';
    return error;
  }

  // Check if email is in a valid format
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) {
    error.message = 'Email is not in a valid format';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
