export function createUserValidate(fullName, email, password, status, type) {
  const error = { message: '' };

  // Check if email and password exist
  if (!fullName && fullName.length <= 1) {
    error.message = 'Full name is required';
    return error;
  }

  if (!password && password.length <= 5) {
    error.message = 'Temporary password is required';
    return error;
  }

  if (!status && status.length <= 1) {
    error.message = 'User access status is required';
    return error;
  }

  if (!type && type.length <= 1) {
    error.message = 'User type is required';
    return error;
  }

  // Check if email is in a valid format
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) {
    error.message = 'Email is not in a valid format';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
