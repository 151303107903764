import { Box, Button, Menu, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function SelectDates({ title, removeAll, dateRemove, setDateRemove, setStartDate, setEndDate }) {
  const [tempStartDate, setTempStartDate] = useState(dayjs());
  const [tempEndDate, setTempEndDate] = useState(dayjs());

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (newValue) => {
    if (tempEndDate && newValue.isAfter(tempEndDate)) {
      toast.error('Start date cannot be after end date');
    } else {
      setTempStartDate(newValue);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (tempStartDate && newValue.isBefore(tempStartDate)) {
      toast.error('End date cannot be before start date');
    } else {
      setTempEndDate(newValue);
    }
  };

  const handleSelectDates = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dateRemove) {
      setTempStartDate(dayjs());
      setTempEndDate(dayjs());
    }
    return () => {
      setDateRemove(false);
    };
  }, [dateRemove]);

  useEffect(() => {
    if (removeAll) {
      setTempStartDate(dayjs());
      setTempEndDate(dayjs());
    }
  }, [removeAll]);

  return (
    <Box>
      <Button sx={{ ml: 2 }} onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
        {title || 'Select Date'}
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontWeight: 'bold', pb: 2 }}>Select Date Range</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                label="Start Date"
                value={tempStartDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
              <DatePicker label="End Date" value={tempEndDate} onChange={(newValue) => handleEndDateChange(newValue)} />
            </DemoContainer>
          </LocalizationProvider>
          <Box className="flex justify-end align-center" sx={{ mt: 2 }}>
            <Button variant="outlined" disabled={!tempStartDate || !tempEndDate} onClick={handleSelectDates}>
              Select Date Range
            </Button>
          </Box>
        </Box>
      </StyledMenu>
    </Box>
  );
}
