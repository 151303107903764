import { Box, Card, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import Avatar from 'boring-avatars';
import Profile from './Profile';
import CompanyUsersTable from './CompanyUsersTable';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SettingsContainer({ user, company }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item md={12} sx={{ width: '100%' }}>
          <Card
            sx={{
              color: 'rgb(255, 255, 255)',
              background: `linear-gradient(rgba(6, 27, 100, 0.8), rgba(6, 27, 100, 0.8)) center center / cover no-repeat, url(https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
            className="flex justify-center align-center"
          >
            <Stack className="flex justify-center align-center" sx={{ height: '300px' }}>
              <Avatar
                size={56}
                name={user.full_name}
                variant="marble"
                colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
              />

              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="h5">{user.full_name}</Typography>
                <Typography sx={{ opacity: 0.48, fontSize: '14px', margin: '4px 0px 0px' }}>{user.email}</Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                overflow: 'hidden',
                minHeight: '48px',
                display: 'flex',
                width: '100%',
                bottom: '0px',
                zIndex: 9,
                position: 'absolute',
                backgroundColor: 'rgb(255, 255, 255)',
              }}
              className="flex justify-center align-center"
            >
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Users" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Card>

          <Box>
            {value === 0 ? (
              <Profile user={user} company={company} />
            ) : value === 1 ? (
              <CompanyUsersTable user={user} company={company} />
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
