export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_OUTPUT = 'SEARCH_OUTPUT';
export const REMOVE_SEARCH_OUTPUTS = 'REMOVE_SEARCH_OUTPUTS';

export const EXTERNAL_DB_USER = 'EXTERNAL_DB_USER';

export const TRAINING_IN_PROGRESS = 'TRAINING_IN_PROGRESS';
export const TRAINING_COMPLETED = 'TRAINING_COMPLETED';
export const CLEAR_TRAINING = 'CLEAR_TRAINING';

export const UPLOADED_DOCUMENTS = 'UPLOADED_DOCUMENTS';
export const GET_COMPANY_DOCUMENTS = 'GET_COMPANY_DOCUMENTS';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const DOCUMENTS_DELETED = 'DOCUMENTS_DELETED';
export const DOCUMENT_DELETION_IN_PROGRESS = 'DOCUMENT_DELETION_IN_PROGRESS';
export const CREATE_USER_IN_PROGRESS = 'CREATE_USER_IN_PROGRESS';
export const CLEAR_ALL = 'CLEAR_ALL';
