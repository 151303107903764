export function newPasswordValidate(fullName, newPassword, newPasswordConfirm) {
  const error = { message: '' };

  // Check if email and password exist
  if (!newPassword || !newPasswordConfirm) {
    error.message = 'Passwords are required';
    return error;
  }
  if (fullName.length <= 3) {
    error.message = 'Please enter a valid full name!';
    return error;
  }

  if (newPassword.length <= 5 || newPasswordConfirm.length <= 5) {
    error.message = 'Password must be greater than 5 characters';
    return error;
  }

  if (newPassword !== newPasswordConfirm) {
    error.message = 'Passwords do not match! Please try again';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
