import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useResponsive from '../../../hooks/useResponsive';

export default function Questions() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">Frequently Asked Questions</Typography>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">How to use this AI tool?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This tool serves as an invaluable resource for anyone seeking information about prior authorization,
                  market access and field reimbursement. It offers rapid and accurate answers to your queries, drawing
                  from a vast database of meticulously curated information. Whether you're a field reimbursement
                  specialist, prior authorization coordinator, payer relations manager, pharmacist, researcher,
                  healthcare professional, or simply someone interested in understanding these complex topics, this tool
                  can provide you with comprehensive insights, saving you time and effort in your quest for knowledge
                  and understanding.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">Who is the ACMA?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  ACMA, the Accreditation Council for Medical Affairs, sets quality and competency standards for the
                  life sciences and pharma industry. Established in 2015, it's an independent accrediting body in the
                  life sciences sector. ACMA credentials individuals and accredits practices to ensure rigorous
                  quality/competency standards globally, advancing patient care.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">How can I train the AI with my company's data?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Training our Field Reimbursement & Prior Authorization AI system is a simple process. Just navigate to
                  the 'Train' tab, and upload any documents you want to use for AI training. Once the AI is trained, it
                  will comprehensively learn and analyze all the text contained within those documents.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What is the difference between external and internal data?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  When you choose internal data, the AI exclusively sources answers from the documents you've trained
                  Field Reimbursement & Prior Auth AI with. Conversely, selecting external data allows the AI to utilize
                  ACMA's comprehensive field reimbursement & prior auth data compendium to respond to your queries.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What type of documents can be trained to Field Reimbursement & Prior Auth AI?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Field Reimbursement & Prior Auth AI can be trained with a range of file formats, including PDFs, text
                  files, Excel documents, images, and we are actively working on incorporating compatibility for audio
                  files, doc files, and video files into our roadmap.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What types of questions can this tool answer?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This tool is designed to answer a broad spectrum of questions related to prior authorization, market
                  access and field reimbursement. You can ask about definitions, inquire about regulatory processes,
                  insurance policies, out of network waivers, prior authorizations and much more.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">Is this tool secure?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Absolutely, security is a top priority for any of our tools. Be assured, your search questions aren’t
                  stored in our database.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
