import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API, DELETE_S3_DOCUMENT_API } from '../../API';
import { DOCUMENTS_DELETED, DOCUMENT_DELETION_IN_PROGRESS } from '../../types';

const deleteDocumentFromS3 = async (email, token, documents) => {
  try {
    const res = await axios.get(DELETE_S3_DOCUMENT_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        documents: JSON.stringify(documents),
      },
    });
    if (res && res.data) {
      res.data.forEach(async (item, index) => {
        await axios.delete(item.url);
      });
      return res.data;
    }
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const deleteDocument = (documents) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  dispatch({
    type: DOCUMENT_DELETION_IN_PROGRESS,
    payload: true,
  });
  try {
    const res = await axios.delete(DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        documents: JSON.stringify(documents),
      },
    });
    if (res && res.data) {
      const documentsToDelete = res.data;
      const deletedDocumentsResponse = await deleteDocumentFromS3(email, token, documentsToDelete);
      dispatch({
        type: DOCUMENTS_DELETED,
        payload: res.data,
      });
      const successfulDocuments = res.data.filter((item) => item.success);
      const successfulDocumentNames = successfulDocuments
        .slice(0, 3)
        .map((item) => item.document)
        .join(', ');
      const unsuccessfulDocuments = res.data.filter((item) => item.success === false);
      const unsuccessfulDocumentNames = unsuccessfulDocuments
        .slice(0, 3)
        .map((item) => item.document)
        .join(', ');
      if (successfulDocumentNames) {
        toast.success(`Documents Deleted Successfully: ${successfulDocumentNames} ...`);
      }

      if (unsuccessfulDocumentNames) {
        toast.error(`Documents Did Not Delete Successfully: ${unsuccessfulDocumentNames}`);
      }
      dispatch({
        type: DOCUMENT_DELETION_IN_PROGRESS,
        payload: false,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_DELETION_IN_PROGRESS,
      payload: false,
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
