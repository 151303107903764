import { Auth } from 'aws-amplify';
import axios from 'axios';
import { EXTERNAL_DB_USER } from '../../types';
import { GET_AUTH_USER_API } from '../../API';

export const getAuthUser = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  // console.log('GET_AUTH_USER_API', GET_AUTH_USER_API);
  try {
    // Make an API call to your external database to retrieve user info
    const response = await axios.get(GET_AUTH_USER_API, {
      params: {
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log('user auth', response);
    if (response.status === 200) {
      const userInfo = response.data;
      dispatch({
        type: EXTERNAL_DB_USER,
        payload: userInfo,
      });
      return { success: true, userInfo };
    }

    return { success: false, userInfo: null };
  } catch (error) {
    console.log('error getting user info', error);
    return { success: false, message: error.response.data.message };
  }
};
