import { CLEAR_ALL, EXTERNAL_DB_USER, GET_COMPANY_USERS, CREATE_USER_IN_PROGRESS } from '../types';

const initialState = {
  userCompanyDetails: {},
  userCompanyDetailsLoading: true,
  companyUsers: [],
  companyUsersLoading: true,
  userCreationInProgress: false,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_IN_PROGRESS:
      return {
        ...state,
        userCreationInProgress: action.payload,
      };
    case GET_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload,
        companyUsersLoading: false,
      };
    case EXTERNAL_DB_USER:
      return {
        ...state,
        userCompanyDetails: action.payload,
        userCompanyDetailsLoading: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        userCompanyDetails: {},
        userCompanyDetailsLoading: true,
        companyUsers: [],
        companyUsersLoading: true,
        userCreationInProgress: false,
      };
    default:
      return state;
  }
}
