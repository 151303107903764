import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Container, Button, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// utils
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { bgBlur } from '../../../utils/cssStyles';
// components
//
import Logo from '../../../components/logo/Logo';
import CNMAPInfoMenu from './CNMAPInfoMenu';
import ResourcesMenu from './ResourcesMenu';
import useResponsive from '../../../hooks/useResponsive';
import LogoBlack from '../../../components/logo/LogoBlack';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const navigate = useNavigate();
  return (
    <StyledRoot sx={{}}>
      <Container>
        <StyledToolbar disableGutters>
          <Box sx={{ width: '15%' }}>
            <LogoBlack />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                ml: 3,
              }}
            >
              Get Started
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{
                ml: 3,
              }}
              onClick={(e) => navigate('/login')}
            >
              Login
            </Button>
          </Stack>
        </StyledToolbar>
      </Container>
    </StyledRoot>
  );
}
