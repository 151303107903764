import { CLEAR_ALL, SEARCH_OUTPUT, SEARCH_LOADING, REMOVE_SEARCH_OUTPUTS } from '../types';

const initialState = {
  searchOutputLoading: false,
  searchOutput: '',
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case SEARCH_LOADING:
      return {
        ...state,
        searchOutputLoading: action.payload,
      };
    case SEARCH_OUTPUT:
      return {
        ...state,
        // searchOutputLoading: false,
        searchOutput: action.payload,
      };
    case REMOVE_SEARCH_OUTPUTS:
      return {
        ...state,
        searchOutput: '',
      };
    case CLEAR_ALL:
      return {
        ...state,
        searchOutputLoading: false,
        searchOutput: '',
      };
    default:
      return state;
  }
}
