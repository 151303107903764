import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import FlareIcon from '@mui/icons-material/Flare';
import GppGoodIcon from '@mui/icons-material/GppGood';
import StarIcon from '@mui/icons-material/Star';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../../components/logo/Logo';

export default function MedAffairsMenuMobile({ iconColor }) {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });
  const anchor = 'left';
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleBenefits = () => {
    navigate('/benefits');
  };

  const handleWhy = () => {
    navigate('/why-medaffairs-ai');
  };

  const handleFAQs = () => {
    navigate('/questions');
  };

  const handleDemo = () => {
    navigate('/demo');
  };

  return (
    <div>
      <React.Fragment key={anchor}>
        <IconButton
          sx={{ color: iconColor || 'white', padding: '0px' }}
          aria-label="delete"
          size="large"
          onClick={toggleDrawer(anchor, true)}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>
        {/* <Button onClick={toggleDrawer(anchor, true)}>Left</Button> */}
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          <Box sx={{ width: '270px', mt: 3 }}>
            <Link to="/">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/pacs_ai/priorauthAI_blue.png"
                alt="Logo"
                width={'70%'}
              />
            </Link>

            <List>
              <ListItem>
                <ListItemButton onClick={handleBenefits} disableGutters>
                  <ListItemIcon>
                    <GppGoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="AI Benefits" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleWhy} disableGutters>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Why Reimbursement AI?" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleFAQs} disableGutters>
                  <ListItemIcon>
                    <LiveHelpIcon />
                  </ListItemIcon>
                  <ListItemText primary="FAQs" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleDemo} disableGutters>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Request a Quote" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
